@import "../../foundation/setting";

.p-upload {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
    @include mq('max') {
        grid-row-gap: 1rem;
    }
}

.p-upload__item {
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
}

.p-upload__text {
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: 60rem;
    margin: 0 auto;
    @include mq('max') {
       flex-direction: column;
    }
}

.p-password-form {
    background-color: $color-graytone04;
    padding: 6rem 8rem;
    border-radius: 20px;
    margin-bottom: 5rem;
    @include mq('max') {
        padding: 2.5rem;
        margin-bottom: 2.5rem;
    }
    select,
    input[type="text"],
    textarea {
        margin-top: 0;
    }
}

.p-mypage {
    padding-bottom: 3rem;
    border-bottom: 2px solid $color-maintone01;
    margin-bottom: 10rem;
    @include mq('max') {
        margin-bottom: 5rem;
    }
}

.p-mypage__title {
    font-size: 2.4rem;
    font-weight: bold;
    @include mq('max') {
        font-size: 1.8rem;
    }
}

.p-mypage__name {
    color: $color-maintone01;
}